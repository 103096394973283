import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from "@angular/forms";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AuthService } from "./services/auth";
import { AlertService } from "./services/alert";
import { SocketService } from "./services/socket";
import { AgoraService } from './services/agora';
import { AgoraSocketService } from './services/agoraSocket';
import { MessagingService } from './services/messaging.service';
import { RequestInterceptorService } from "./services/http.interceptor";
import { VideoProcessingService } from './services/video-processing-service';
import { ScrollToBottomDirective } from './services/scroll-to-bottom.directive';

import { NgOtpInputModule } from 'ng-otp-input';
import { AuthGuard } from "./guards/auth.guard";
import { GuestGuard } from "./guards/guest.guard";
import { LoaderComponent } from "./shared/loader/loader.component";
import { CommonModule, HashLocationStrategy, LocationStrategy, PathLocationStrategy, } from "@angular/common";
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ChartsModule } from 'ng2-charts';

import { environment } from '../environments/environment';
import { AsyncPipe } from '../../node_modules/@angular/common';

import { TuneService } from './services/tune';
import { unReadMsgService } from './services/unread-msg-count';
import { VideoRecordingService } from './services/video-recording.service';
import { PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    ScrollToBottomDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    CommonModule,
    HttpClientModule,
    NgOtpInputModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    ChartsModule,
    PickerModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    // NgxAgoraModule.forRoot({ AppID: environment.appId }),
  ],
  providers: [
    AlertService,
    AuthGuard,
    GuestGuard,
    // { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    AuthService,
    SocketService,
    AgoraSocketService,
    VideoProcessingService,
    VideoRecordingService,
    TuneService,
    unReadMsgService,
    AgoraService,
    MessagingService, AsyncPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }