import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth";
// Socket
import io from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable()
export class AgoraSocketService {

    public liveMsg: BehaviorSubject<any> = new BehaviorSubject<string>(null);

    msgObj: any;

    socket: any;
    connected = false;
    agoraUrl = environment.chatUrl + '/golive';

    constructor(
        private auth: AuthService
    ) {
        if (!this.connected) {
            this.createSocketClient();
            return;
        }
        alert('Already Socket connected.');
    }

    createUserInlineInfo(user) {
        return `${user.username} (${user.name || user.email})`;
    }

    createSocketClient() {
        console.info('socket: connecting');
        console.log("SOCKET: this.socket.auth.token", this.auth.token());
        this.socket = io(this.agoraUrl, {
            transports: ["websocket"],
            auth: {
                token: "Bearer " + this.auth.token(),
            },
        });

        this.socket.on('connect', () => {
            this.connected = true;
            console.log('this.connected', this.socket)

        });

        this.socket.on('disconnect', () => {
            console.info('socket: disconnected');
            this.connected = false;
        });

        this.socket.on('error', console.error);

        // Live Msg
        this.socket.on('message', (data) => {
            console.log('socket: message', data);

            this.msgObj = {
                content: data.message.content,
                createdAt: data.message.createdAt,
                liveId: data.message.liveId,
                user: {
                    created_at: data.user.createdAt,
                    email: data.user.email,
                    id: data.user.id,
                    name: data.user.name,
                    profile_image: data.user.profile_image,
                    role: data.user.role,
                    updated_at: data.user.updated_at,
                    username: data.user.username,
                },
                userId: data.message.userId,
                username: data.message.username,
                _id: data.message._id,
            }


            this.liveMsg.next(this.msgObj);

            // createMessage(data.message, data.user);
        });

        this.socket.on('subscribed', ({ subscription, goliveId }) => {
            console.log(`socket: subscribed ${subscription} ${goliveId || ''}`);

            // if (goliveId) {
            //   currentGoLiveId = goliveId;
            //   threadWindowEle.style.display = 'block';
            //   loadMessages();
            //   return;
            // }
        });

    }


    //for send massage on live 
    sendGoLiveId(id) {
        this.socket.emit('subscribe:golive', { goliveId: id });
    }

}