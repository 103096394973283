import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { AuthService } from "./auth";
// Socket
import io from "socket.io-client";
import { environment } from "src/environments/environment";

@Injectable()
export class SocketService {

  // Group Media Get
  attachSenderName = new BehaviorSubject<{}>(null);
  statesenderName(s: any) {
    this.attachSenderName.next(s);
  }

  // Group Media Get
  attachNewGroupCreate = new BehaviorSubject<{}>(null);
  stateGroup(s: any) {
    this.attachNewGroupCreate.next(s);
  }

  // User Live Status
  attachLiveStatus = new BehaviorSubject<{}>(null);
  userLiveOnlineStatus(s: any) {
    this.attachLiveStatus.next(s);
  }

  // User Online List
  attachOnlineList = new BehaviorSubject<{}>(null);
  userOnlineList(s: any) {
    this.attachOnlineList.next(s);
  }

  // User Typing
  attachIsTyping = new BehaviorSubject<{}>(null);
  userIsTyping(t: any) {
    this.attachIsTyping.next(t);
  }

  // Update Group Info
  attachUpdateGroupInfo = new BehaviorSubject<{}>(null);
  stateEditGroup(s: any) {
    this.attachUpdateGroupInfo.next(s);
  }


  public msg: BehaviorSubject<any> = new BehaviorSubject<string>(null);
  socket: any;
  connected = false;
  timeoutRef;
  isTyping = false;
  typeInfo: any;

  chatUrlMsg = environment.chatUrl + '/chat';

  // Media
  public envMedia = environment.mediaUrl;
  public envOrg = environment.mediaUrlOrg;
  public envThumb = environment.mediaUrlThumb;

  constructor(
    private auth: AuthService
  ) {
    if (!this.connected) {
      this.createSocketClient();
      return;
    }
    alert('Already Socket connected.');
  }

  createSocketClient() {
    console.info('socket: connecting');
    console.log("SOCKET: this.socket.auth.token", this.auth.token());
    this.socket = io(this.chatUrlMsg, {
      transports: ["websocket"],
      auth: {
        token: "Bearer " + this.auth.token(),
      },
    });

    this.socket.on('connect', () => {
      console.info('socket: connected', this.socket);
      this.connected = true;
      console.log('this.connected', this.socket)
      // socketStatus.innerText = 'CONNECTED';
      this.socket.emit('subscribe:chat');
    });

    this.socket.on('disconnect', () => {
      console.info('socket: disconnected');
      this.connected = false;
      // socketStatus.innerText = 'NOT CONNECTED';
    });

    this.socket.on('error', console.error);

    this.socket.on('message', ({ message, sender, thread, userThread }) => {

      console.log(' message', message);
      console.log(' sender', sender);
      console.log(' thread', thread);
      console.log(' userThread', userThread);
      console.log('socket: message received', message, 'from', sender.name, 'thread', thread ? thread.name : 'NON');
      this.msg.next(message);
      this.stateGroup(userThread);

      this.statesenderName(sender);
      // const pEle = document.createElement('p');
      // pEle.innerHTML = `U${sender.id}: ${message.content}`;
      //consoleEle.appendChild(pEle);
    });

    // this.socket.on("message", ({message, sender, thread, userThread}) => {
    //   console.log("SOCKET: stageJoined",);
    //   this.msg.next(message);
    //   this.stateGroup(userThread);
    // });



    this.socket.on('user:status', (data) => {
      console.log('socket: user:status', data);

      this.userLiveOnlineStatus(data)

      // const threadName = document.querySelector(`#uid-${data.uid}-s`);
      // if (threadName) {
      //   console.log('socket: user:status thread name found')
      //   if (data.online) {
      //     threadName.classList.add('online');
      //   } else {
      //     threadName.classList.remove('online');
      //   }
      // } else {
      //   console.log('socket: user:status thread name not found', data.uid);
      // }
    });

    this.socket.on('user:friends', (data) => {
      console.log('socket: user:friends', data.online);
      this.userOnlineList(data.online);
      console.log('data.online', data.online);
      // if (data.online && data.online.length) {
      //   data.online.forEach((uid) => {
      //     const threadName = document.querySelector(`#uid-${uid}-s`);
      //     if (threadName) {
      //       console.log('socket: user:friends thread name found')
      //       threadName.classList.add('online');
      //     } else {
      //       console.log('socket: user:friends thread name not found', uid);
      //     }
      //   });
      // }
    });


    this.socket.on('thread:typing', ({ typing, user, threadId }) => {
      console.log(`socket: typing ${typing} by ${user.username}`);

      this.typeInfo = {
        typing: 'typing...',
        typingStatus: typing,
        typingUserId: user.id,
        typingthreadId: threadId,
        typingUserInfo: (user.name + ' ' + 'is typing...')
      }

      // this.typeInfo = (typing +' ' + user.username + ' ' + 'is typing...');
      console.log('this.typeinfo', this.typeInfo);

      this.userIsTyping(this.typeInfo);

      const elId = `typing-${user.id}`;

      // find thread item message element
      const threadItemEle = document.getElementById(`tid-${threadId}`);
      let threadItemMsgEle;
      if (threadItemEle) {
        threadItemMsgEle = threadItemEle.querySelector('.thread-msg');
      }

      // if (typing) {
      //   if (threadItemMsgEle) {
      //     threadTypingsMap[threadId] = { oldContent: threadItemMsgEle.innerHTML };
      //   }
      //   const p = document.createElement('p');
      //   p.setAttribute('id', elId);
      //   threadTypingsMap[threadId].typingMessage = threadItemMsgEle.innerHTML = p.innerHTML = `${user.username} is typing...`;
      //   //whoIsTyping.appendChild(p);
      // } else {
      //   document.getElementById(elId).outerHTML = '';
      //   if (threadItemMsgEle) {
      //     if (threadItemMsgEle.innerHTML === threadTypingsMap[threadId].typingMessage) {
      //       threadItemMsgEle.innerHTML = threadTypingsMap[threadId].oldContent || '';
      //     }
      //   }
      // }

    });



    this.socket.on('thread:update', (data) => {
      console.log('socket: thread:update', data);
      this.stateEditGroup(data);
      //this.userLiveOnlineStatus(data)

    });


    // this.socket.on("agoraBroadcastingClients", (data: any) => {
    //   console.log("SOCKET: agoraBroadcastingClients", data);
    // });


  }


  joinChat(id) {
    this.socket.emit('subscribe:thread', { threadId: id });
  }

  sendMsg(msgValue) {
    console.log('msgValue', msgValue)
    this.socket.emit('message:send', { content: msgValue });
  }


  typingTimeOut() {
    this.socket.emit('thread:typing', { typing: false });
    this.isTyping = false;
    console.log(`ui: typing stopped`);
  }

  typing() {
    console.log(`ui: you are typing`);
    if (this.isTyping) {
      return;
    }
    this.isTyping = true;
    this.socket.emit('thread:typing', { typing: true });
    clearTimeout(this.timeoutRef);
    this.timeoutRef = setTimeout(() => {
      this.typingTimeOut();
    }, 3000);
  }





}















//   const socket = io({
//     extraHeaders: {
//       "my-custom-header": "1234"
//     }
//   });


//     this.socket = this.envApiUrl
//   }

// connectSocket() {
//   // this.eventId = eventId;
//   // this.eventType = this.eventType;

//   this.socket.on("connect", () => {
//     console.log("SOCKET: connected");
//     //console.log("SOCKET: EVENT ID", eventId);
//     console.log("SOCKET: this.socket.auth.token", this.auth.token());
//     this.socket.emit("subscribe", {
//       type: "auth",
//       moduleId: "auth",
//       token: this.auth.token(),
//     });
//   });

//   this.socket.on("authorized", () => {
//     console.log("SOCKET: authorized");
//     this.socket.emit("subscribe", {
//       // type: type,
//       // moduleId: eventId,
//       token: this.auth.token(),
//     });
//   });

//   this.socket.on("disconnect", (data: any) => {
//     console.log("SOCKET: disconnect", data);
//   });

//   this.socket.on("connect_error", () => {
//     setTimeout(() => {
//       this.socket.connect(this.eventId, this.eventType);
//       console.log("SOCKET: this.socket.auth.token", this.auth.token());
//     }, 1000);
//   });

//   this.socket.on("error", (data: any) => {
//     console.log("SOCKET: error", data);
//   });

//   // this.socket.on("appError", (data: any) => {
//   //   console.log("appError", data);
//   // });

//   // this.socket.on("appMessage", (data: any) => {
//   //   console.log("appMessage", data);
//   // });

//   // test
//   this.socket.on("subscribed", (data: any) => {
//     console.log("SOCKET: subscribed", data);
//   });

//   this.socket.on("unsubscribed", (data: any) => {
//     console.log("SOCKET: unsubscribed", data);
//   });

//   // this.socket.on("unsubscribed", () => {
//   //   console.log("unsubscribed");
//   //   this.socket.emit("unsubscribed", {
//   //     type: this.eventType,
//   //     moduleId: this.eventId,
//   //     token: this.auth.token(),
//   //   });
//   // });

//   // this.socket.on("danceFloor", (data: any) => {
//   //   console.log("SOCKET: danceFloor", data);
//   // });

//   // this.socket.on("stageJoined", (data: any) => {
//   //   console.log("SOCKET: stageJoined", data);
//   //   this.stateStageJoin(data);
//   // });

//   // this.socket.on("stageLeft", (data: any) => {
//   //   console.log("SOCKET: stageLeft", data);
//   //   this.stateStageLeft(data);
//   // });

//   // this.socket.on("eventCurrentHost1", (data: any) => {
//   //   console.log("SOCKET: eventCurrentHost1", data);
//   //   this.state1(data);
//   // });

//   // this.socket.on("eventCurrentHost2", (data: any) => {
//   //   console.log("SOCKET: eventCurrentHost2", data);
//   //   this.state2(data);
//   // });

//   this.socket.on("agoraBroadcastingClients", (data: any) => {
//     console.log("SOCKET: agoraBroadcastingClients", data);
//   });

//   this.socket.on("resubscribe", (data: any) => {
//     console.log("SOCKET: resubscribe", data);
//     this.socket.emit("subscribe", {
//       roomId: data.roomId,
//       // type: type,
//       // moduleId: eventId,
//       token: this.auth.token(),
//     });
//   });

//   // this.socket.on("new-message", (message) => {
//   //   this.socket.emit(message);
//   // });

//   // this.socket.on("message", (message) => {
//   //   console.log("Message Received: " + message);
//   //   this.socket.emit("message", { type: "new-message", text: message });
//   // });

//   // this.socket.on('subscribed', ({ c }) => {
//   //   all other vibe related events should be emited after subscribed to vibe success
//   //   })
// }
// }
