<div class="container-fluid pl-0">
    <div class="row">

        <div class="col-md-6 pl-0 bgBack">
            <!-- <div class="Login-left-div pr-0">
          <img src="assets/images/img_bg.png" />
        </div> -->
        </div>

        <div class="col-md-6 pr-0 pr-md-3 ipadMedia">
            <router-outlet></router-outlet>
        </div>

    </div>
</div>