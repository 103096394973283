// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  accept: "application/json",
  content_type: "application/json",

  appId: "ef1f3dc6ecf54a51bbb93cf6711b3f15",

  // appId: "6477f16b4aee4fb3a4c372c393fe301d",
  // appCertificate: "b6c2b036b1a342428b9200686faabb9b",

  // Dev

  // chatUrl: "http://192.168.88.171:3000",


  // chatUrl: "https://dev-chat.viewus.com",
  // copyUrl: "http://192.168.88.52:4200/#/",
  // bitSafeUrl: "http://103.149.154.53/ViewUs/public",
  // apiUrl: "http://103.149.154.53/ViewUs/public/api",
  // mediaUrl: "https://viewusimages.s3.amazonaws.com/",
  // mediaUrlOrg: "https://viewusimages.s3.amazonaws.com/dev/original/",
  // mediaUrlThumb: "https://viewusimages.s3.amazonaws.com/dev/thumbnail/",

  // firebase: {
  //   apiKey: "AIzaSyBLfaArGLoZdoQoCHEs5Ww4ns3cK9VPPuo",
  //   authDomain: "viewus-257b0.firebaseapp.com",
  //   databaseURL: "http://viewus-257b0.firebaseio.com",
  //   projectId: "viewus-257b0",
  //   storageBucket: "viewus-257b0.appspot.com",
  //   messagingSenderId: "582288844048",
  //   appId: "1:582288844048:web:77516b5bf39538144c3195",
  //   measurementId: "G-L2P2Q77298"
  // }

  // QA

  // chatUrl: "https://dev-chat.viewus.com",
  // //chatUrl: "http://103.149.154.53:3000",
  // copyUrl: "http://103.149.154.53/view-us-qa/#/",
  // apiUrl: "http://103.149.154.53/ViewUs/public/api",
  // mediaUrl: "https://viewusimages.s3.amazonaws.com",
  // mediaUrlOrg: "https://viewusimages.s3.amazonaws.com/dev/original/",
  // mediaUrlThumb: "https://viewusimages.s3.amazonaws.com/dev/thumbnail/",
  // bitSafeUrl: "https://api.viewus.com",

  // Client

  // apiUrl: "http://viewus-env.eba-8vqipdst.us-east-1.elasticbeanstalk.com/api",
  // bitSafeUrl: "http://viewus-env.eba-8vqipdst.us-east-1.elasticbeanstalk.com",

  chatUrl: "https://stag-chat.viewus.com",
  // copyUrl: "https://portal.viewus.com/#/",
  // copyUrl: "https://viewus.com/#/",
  copyUrl: "https://viewus.com/",
  apiUrl: "https://api.viewus.com/api",
  bitSafeUrl: "https://api.viewus.com",
  // notiFicationIcon: "https://portal.viewus.com/favicon.ico",
  notiFicationIcon: "https://viewus.com/favicon.ico",
  mediaUrl: "https://viewusimages.s3.amazonaws.com",
  mediaUrlOrg: "https://viewusimages.s3.amazonaws.com/dev/original/",
  mediaUrlThumb: "https://viewusimages.s3.amazonaws.com/dev/thumbnail/",

  firebase: {
    apiKey: "AIzaSyA-I0gTbeqKdRDmEqLW5pR7CT9S2A2F_bA",
    authDomain: "viewus-72ee7.firebaseapp.com",
    databaseURL: "http://viewus-72ee7.firebaseio.com",
    projectId: "viewus-72ee7",
    storageBucket: "viewus-72ee7.appspot.com",
    messagingSenderId: "301268728887",
    appId: "1:301268728887:web:bd83fa4f6f6b3b305ad3b2",
    measurementId: "G-Z82P38HHGG"


    // apiKey: "AIzaSyBUc72ZSkMFjI-hiKeAR1ye422Axu-15mE",
    // authDomain: "reins-demo.firebaseapp.com",
    // databaseURL: "https://reins-demo.firebaseio.com",
    // projectId: "reins-demo",
    // storageBucket: "reins-demo.appspot.com",
    // messagingSenderId: "83549988349",
    // appId: "1:83549988349:web:fc2d07dfc734c2b375e594",
    // measurementId: "G-EL1VRFKPY2"

  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.