import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from "./guards/auth.guard";
import { GuestGuard } from "./guards/guest.guard";
import { GuestLayoutComponent } from './modules/guest-layout/guest-layout.component';
import { PortalLayoutComponent } from './modules/portal-layout/portal-layout.component';

const routes: Routes = [
  {
    path: '',
    component: GuestLayoutComponent,
    canActivate: [GuestGuard],
    loadChildren: () =>
      import('./modules/guest-layout/guest-layout.module').then(
        (mod) => mod.GuestLayoutModule
      ),
  },
  {
    path: '',
    // component: PortalLayoutComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/portal-layout/portal-layout.module').then(
        (mod) => mod.PortalLayoutModule
      ),
  },
  // {
  //   path: ":userName",
  //   component: ProfileComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }