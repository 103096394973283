import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs'
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { MessagingService } from './messaging.service';

@Injectable({
    providedIn: 'root'
})

export class unReadMsgService {

    constructor(
        private http: HttpClient,
        public messagingService: MessagingService
    ) {
        this.getMessageCount();
    }

    msgUnreadCount = new BehaviorSubject(false);

    getMessageCount() {
        //this.loader.state(true);
        let customHeaders = new HttpHeaders().set('x-node', 'x-node')
        this.http.get('chat/unread/count', { headers: customHeaders }).subscribe(
            (data: any) => {
                console.log('data', data);
                // this.msgUnreadCount = data.data.count;
                // console.log('this.msgUnreadCount', this.msgUnreadCount);

                if (data.data.count > 0) {
                    console.log('inside');
                    this.msgUnreadCount.next(true);
                    return;
                }
                this.msgUnreadCount.next(false);

                this.messagingService.getRedMsgCount(false);
            },
            error => {
                //this.loader.state(false);
                if (error.status === 422) {
                    // this.errors = error.error.errors;
                    // console.log(this.errors);
                }
            }
        );
    }

}