import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";
import AgoraRTC from "agora-rtc-sdk-ng"
import { LoaderService } from './loader';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})

export class AgoraService {


    public liveEnded: BehaviorSubject<any> = new BehaviorSubject<string>(null);

    // Time
    timeduration: any;
    channelId = '';
    rtc: any = {
        localAudioTrack: null,
        localVideoTrack: null,
        client: null
    };

    options: any = {
        appId: environment.appId,
        channel: "test",
        token: "Your temp token",
        uid: 123456
    };

    public env = environment.mediaUrl;

    constructor(
        private loader: LoaderService,
    ) {
        // Create an AgoraRTCClient object.
        this.rtc.client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

        this.rtc.client.on("user-published", async (user: any, mediaType: any) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event
            await this.rtc.client.subscribe(user, mediaType);
            console.log("subscribe success");

            let remoteVideoTrack: any;
            // If the remote user publishes a video track.
            if (mediaType === "video") {
                remoteVideoTrack = user.videoTrack;
                remoteVideoTrack.play(document.getElementById('222-' + user.uid));
            }

            // If the remote user publishes an audio track.
            if (mediaType === "audio") {
                const remoteAudioTrack = user.audioTrack;
                remoteAudioTrack.play();
            }

        });
    }

    // Remote Audience
    startBasicCall() {
        // Listen for the "user-published" event, from which you can get an AgoraRTCRemoteUser object.
        this.rtc.client.on("user-published", async (user: any, mediaType: any) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event
            await this.rtc.client.subscribe(user, mediaType);
            console.log("subscribe success");

            let remoteVideoTrack: any;
            // If the remote user publishes a video track.
            if (mediaType === "video") {
                remoteVideoTrack = user.videoTrack;
                remoteVideoTrack.play();
            }

            // If the remote user publishes an audio track.
            if (mediaType === "audio") {
                const remoteAudioTrack = user.audioTrack;
                remoteAudioTrack.play();
            }

        });
    }


    async startCall(channelId: any) {
        this.channelId = channelId;
        // Join an RTC channel.
        await this.rtc.client.join(environment.appId, channelId, null);
        // Create a local audio track from the audio sampled by a microphone.
        this.rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
        // Create a local video track from the video captured by a camera.
        this.rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();
        // Publish the local audio and video tracks to the RTC channel.
        await this.rtc.client.publish([this.rtc.localAudioTrack, this.rtc.localVideoTrack]);
        // Dynamically create a container in the form of a DIV element for playing the local video track.

        // Play the local video track.
        // Pass the DIV container and the SDK dynamically creates a player in the container for playing the local video track.
        console.log("play video !");
        this.rtc.localVideoTrack.play(document.getElementById('111-' + channelId));
        console.log("publish success!");
    }


    async reciveCall(channelId: any) {

        console.log('reciveCalllllllllllllllllllllllllllllllllllllllll');
        // Join an RTC channel.
        // await this.rtc.client.join(environment.appId, channelId, null);
        console.log('reciveCall user-published');
        this.rtc.client.on("user-published", async (user: any, mediaType: any) => {
            // Subscribe to the remote user when the SDK triggers the "user-published" event
            console.log("before subscribe success");
            await this.rtc.client.subscribe(user, mediaType);
            console.log("subscribe success");

            let remoteVideoTrack: any;
            // If the remote user publishes a video track.
            if (mediaType === "video") {
                remoteVideoTrack = user.videoTrack;
                remoteVideoTrack.play(document.getElementById('222-' + channelId));
            }

            // If the remote user publishes an audio track.
            if (mediaType === "audio") {
                const remoteAudioTrack = user.audioTrack;
                remoteAudioTrack.play();
            }

            // Listen for the "user-unpublished" event
            this.rtc.client.on("user-unpublished", (user: any) => {

                console.log('user-unpublished user', user);

                // Get the dynamically created DIV container.
                let remotePlayerContainer: any = document.getElementById('222-' + channelId);
                // Destroy the container.
                if (!user._video_added_ && !user._audio_added_) {
                    // Open Modal
                    this.liveEnded.next('openModal');

                    setTimeout(() => {
                        remotePlayerContainer.remove();
                    }, 1000);
                }
            });

        });
    }


    async leaveCall() {
        this.rtc.localAudioTrack.close();
        this.rtc.localVideoTrack.close();
        await this.rtc.client.leave();
    }

    audioMute() {
        this.rtc.localAudioTrack.setMuted(true);
    }

    audioUnmute() {
        this.rtc.localAudioTrack.setMuted(false);
    }


    videoMute() {
        this.rtc.localVideoTrack.setMuted(true);
    }

    videoUnmute() {
        this.rtc.localVideoTrack.setMuted(false);
    }

    // New
    dd() {
        console.log('sfsg');

        AgoraRTC.onCameraChanged = async (changedDevice) => {
            console.log('changedDevice', changedDevice);

            // When plugging in a device, switch to a device that is newly plugged in.
            console.log('camera changed');

            if (changedDevice.state === "ACTIVE") {
                this.rtc.localVideoTrack.setDevice(changedDevice.device.deviceId);
                // Switch to an existing device when the current device is unplugged.
            } else if (changedDevice.device.label === this.rtc.localVideoTrack.getTrackLabel()) {
                const oldCameras = await AgoraRTC.getCameras();
                oldCameras[0] && this.rtc.localVideoTrack.setDevice(oldCameras[0].deviceId);
            }
        }
    }


    duration() {
        this.rtc.client.AgoraRTCClient.getRTCStats((stats: any) => {
            this.timeduration = stats.Duration;
            console.log('this.timeduration', this.timeduration);
        })
    }


    //     // Time
    //     if(sentData.event == 'CallAccept.Received') {
    //     this.SessionStatsInterval = setInterval(() => {
    //         this.rtc.client.getSessionStats((stats) => {
    //             this.timeduration = stats.Duration;
    //             console.log(`Current Session Duration: ${stats.Duration}`);
    //             console.log(`Current Session UserCount: ${stats.UserCount}`);
    //             console.log(`Current Session SendBytes: ${stats.SendBytes}`);
    //             console.log(`Current Session RecvBytes: ${stats.RecvBytes}`);
    //             console.log(`Current Session SendBitrate: ${stats.SendBitrate}`);
    //             console.log(`Current Session RecvBitrate: ${stats.RecvBitrate}`);
    //         });
    //     }, 1000)
    // }

}