import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class LoaderService {

  attach = new BehaviorSubject<boolean>(false);

  state(s: boolean) {
    this.attach.next(s);
  }
}