import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class MessagingService {

  FCMToken = new BehaviorSubject('');
  currentMessage = new BehaviorSubject(null);
  redMsgBadge = new BehaviorSubject(false);
  redNotificationBadge = new BehaviorSubject(false);

  constructor(
    private angularFireMessaging: AngularFireMessaging
  ) {
    this.angularFireMessaging.messages.subscribe(
      (_messaging: AngularFireMessaging) => {
        _messaging.onMessage = _messaging.onMessage.bind(_messaging);
        _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
      }
    )
  }

  requestPermission() {
    // console.log('test req',);

    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        // console.log(token);
        this.FCMToken.next(token);
        // console.log('this.FCMToken', this.FCMToken);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  receiveMessage() {
    // console.log('Check Receive');
    this.angularFireMessaging.messages.subscribe(
      (payload) => {


        // const NotificationOptions = {
        //   body: payload.notification.body,
        //   data: payload.data,
        //   icon: payload.notification.icon
        // }
        // navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
        //   registration.showNotification(payload.notification.title, NotificationOptions);
        // });

        console.log("new message received. ", payload);
        this.currentMessage.next(payload);
      })
  }

  getRedNotificationCount(count: boolean) {
    this.redNotificationBadge.next(count);
  }

  getRedMsgCount(count1: boolean) {
    this.redMsgBadge.next(count1);
  }

}