import { Injectable } from '@angular/core';

@Injectable()
export class TuneService {
    audio = new Audio();

    constructor() {
    }

    playAudio() {
        this.audio = new Audio();
        // this.audio.src = 'https://www.mobilesringtones.com/static/p/ringtones/2021/04/06/45138/45138.mp3?title=45138_download_illumination_ringtone_cool_ringtones.mp3';
        // this.audio.src = 'assets/audio/illumination_ringtone_cool_ringtones.mp3'
        this.audio.src = 'assets/audio/notification.mp3'
        this.audio.load();
        this.audio.play();
    }

    pauseAudio() {
        this.audio.src = '';
        this.audio.load();
        this.audio.pause();
    }
}