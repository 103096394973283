import { Injectable } from "@angular/core";
import { AuthService } from "../services/auth";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.guest()) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(["/"], { queryParams: { ref: state.url } });
      return false;
    }
    return true;
  }

}