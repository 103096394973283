<app-loader [show]="loading"></app-loader>

<div class="notifications">
    <ngb-toast *ngFor="let toast of toastService.toasts" [class]="toast.classname" [autohide]="true"
        [delay]="toast.delay || 500000" (hide)="toastService.remove(toast)">
        <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
            <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text> 
            <a class="text-white" href="{{urlNotification}}">{{ toast.textOrTpl }}</a> &nbsp;
            <img class="img-fluid" src="{{this.urlIconNoti.length ? this.urlIconNoti:''}}" />
        </ng-template>

    </ngb-toast>
</div>

<!-- {{ message | async | json }} -->

<router-outlet></router-outlet>