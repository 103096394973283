import { Component, OnInit, TemplateRef } from "@angular/core";
import { AlertService } from "./services/alert";
import { LoaderService } from "./services/loader";
import { AuthService } from "src/app/services/auth";
import { RouteConfigLoadStart, RouteConfigLoadEnd, Router, NavigationEnd, } from "@angular/router";
import { MessagingService } from "./services/messaging.service";
import { isDevMode } from '@angular/core';
import { environment } from "src/environments/environment";
import { TuneService } from "./services/tune";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  message;
  userId = '';
  userInfo: any = {};
  title = 'ViewUs';
  loading = false;
  urlIconNoti = '';
  urlNotification: any = '';
  public envCopy = environment.copyUrl;

  constructor(
    private router: Router,
    private auth: AuthService,
    public toastService: AlertService,
    private tune: TuneService,
    private loader: LoaderService,
    public messagingService: MessagingService
  ) {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  ngOnInit() {

    //Console Off 
    // if (isDevMode()) {
    //   console.log = function () { };
    // }

    this.messagingService.requestPermission();
    this.messagingService.receiveMessage();
    this.message = this.messagingService.currentMessage.subscribe((msg: any) => {

      if (msg) {
        console.log('msgapp', msg);
        this.tune.playAudio();
        // this.notificationCount = msg.data.badge;
        this.toastService.show(msg.notification.title + ' ' + msg.notification.body, { classname: 'bg-success text-light', delay: 7000 });
      }

      if (msg?.data.type === 'SUBSCRIBED_POSTED' || msg?.data.type === 'NEW_COMMENT' || msg?.data.type === 'NEW_LIKE') {
        // NotificationRed
        this.messagingService.getRedNotificationCount(true);
        this.urlIconNoti = msg?.data.icon;
        this.urlNotification = this.envCopy + 'post/' + msg.data.module_id;
        this.toastService.show(msg.notification.title + ' ' + msg.notification.body, { classname: 'bg-success text-light', delay: 7000 });
      }

      if (msg?.data.type === 'NEW_SUBSCRIBER' || msg?.data.type === 'SUBSCRIBED_PROMOTION' || msg?.data.type === 'UPDATE_PROFILE') {
        this.messagingService.getRedNotificationCount(true);
        this.urlIconNoti = msg?.data.icon;
        this.urlNotification = this.envCopy + msg.notification.title;
      }1

      if (msg?.data.type === 'GO_LIVE') {
        this.messagingService.getRedNotificationCount(true);
        this.urlIconNoti = msg?.data.icon;
        this.urlNotification = this.envCopy + 'go-live' + '?event=joinLive' + '&' + 'liveUserId=' + msg.data.module_id + '&' + 'liveUsername=' + msg.notification.title;
      }

      if (msg?.data.type === 'NEW_MESSAGE') {
        this.messagingService.getRedMsgCount(true);
        this.tune.playAudio();
        this.urlIconNoti = msg?.data.icon;
        this.urlNotification = this.envCopy + 'messages';
        this.toastService.show(msg.notification.title + ' ' + msg.notification.body, { classname: 'bg-success text-light', delay: 7000 });
      }

      if (msg?.data.type === 'SUBSCRIBED_STORY') {
        this.urlIconNoti = msg?.data.icon;
        this.urlNotification = this.envCopy;
        this.toastService.show(msg.notification.title + ' ' + msg.notification.body, { classname: 'bg-success text-light', delay: 7000 });
      }

    });

    this.userInfo = this.auth.user();
    if (this.userInfo) {
      this.userId = this.userInfo.user_info.id;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loader.state(true);
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loader.state(false);
      }
    });
  }

  ngAfterViewInit() {
    this.loader.attach.subscribe((data) => {
      setTimeout(() => {
        this.loading = data;
      }, 100);
    });
  }

}